/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import '../src/scss/mixins';

/* Core CSS required for Ionic components to work properly */

@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~swiper/swiper-bundle";

.swiper-container {
  width: 100%;
  height: 300px;
  margin: 50px auto;
}

.swiper-slide {
  background: #f1f1f1;
  color: #000;
  text-align: center;
  line-height: 300px;
}

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

.title {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  color: var(--ion-color-dark);

  &--small {
    font-size: 0.688rem;
  }

  &--medium {
    font-size: 1.125rem;
    font-weight: 700;
  }

  &--large {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &--variant {
    font-size: 0.988rem;
    font-variant: small-caps;
  }
}

.font-text {
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--ion-color-dark);

  &--extra-small {
    font-size: 0.625rem;
  }

  &--small {
    font-size: 0.75rem;
  }

  &--large {
    font-size: 1.125rem;
  }

  &--extra-large {
    font-size: 1.875rem;
  }
}

.description-text {
  color: var(--ion-color-medium);
  font-size: 0.75rem;

  &--light {
    font-weight: 300;
  }

  &--small {
    font-size: 0.625rem;
  }

  &--medium {
    font-size: 0.875rem;
  }

  &--large {
    font-size: 1.125rem;
  }

  &--extra-large {
    font-size: 1.5rem;
    font-weight: 700;
  }

  &--variant {
    color: var(--ion-color-ligth);
    font-size: 0.85rem;
    font-variant: small-caps;
  }
}

.text-link {
  cursor: pointer;
  color: var(--ion-color-secondary);
  font-size: 0.875rem;
  font-weight: 700;

  &--small {
    font-size: 0.75rem;
  }

  &--variant {
    font-size: 0.75rem;
    font-variant-numeric: oldstyle-nums;
  }

  &--large {
    font-size: 1.125rem;
  }
}

ion-button {
  margin: auto;
  width: 100%;
  max-width: 400px;
}

.button-outline {
  --border-width: 1px;
  font-weight: 500;
}

.img-facade {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;

  &--document {
    @include for-desktop-up {
      max-width: 80%;
    }
  }
}

.img-document {
  object-fit: contain;
  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 300px;
  ;
}

.content-vertical-space-between {
  height: 84%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--center {
    justify-content: center;
  }
}

ion-modal {
  &.tiny-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 12rem;
    }
  }

  &.smaller-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 16rem;
    }
  }

  &.small-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 15rem;
      height: 20rem;
    }
  }

  &.medium-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 15rem;
      height: 27rem;
    }
  }

  &.contact-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 16rem;
    }
  }

  &.lot-selection-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 20rem;
    }
  }

  &.confirma-envio-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 18rem;
      height: 20rem;
    }
  }

  &.registration-help-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 22.5rem;
    }
  }

  &.registration-submit-help-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 18rem;
    }
  }

  &.documents-help-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 15.5rem;
      height: 31rem;
    }
  }

  &.allotment-unavailable-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 15rem;
      height: 16rem;
    }
  }

  &.allotment-support-modal {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 16rem;
      height: 14rem;
    }
  }

  &.poderegularizar-tutorial {
    --height: 100%;
    --width: 100%;
    --max-height: 100%;
    --max-width: 100%;
  }

  &.exclui-cadastro {
    --border-radius: 8px;

    &::part(content) {
      top: 0;
      margin: auto;
      align-items: center;
      width: 18rem;
      height: 28rem;
    }
  }
}

.my-custom-modal-photo {
  width: 25rem;
  height: 25rem;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  box-shadow: 0px 8px 10px 999999px #00000024;

  @include for-phone-only {
    width: 80vw;
    height: 60vh;
    box-shadow: 0px 8px 10px 999999px #00000024;
  }
}

ion-modal.modal-wrapper {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.custom-searchbar .searchbar-input-container input::placeholder {
  font-size: 14px;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.datepicker-modal {
  --background: #00000025;

  &::part(content) {
    height: 350px;
    width: 290px;
    border-radius: 10px;
    bottom: auto;
  }
}

ion-menu {
  --width: 100%;
  --max-width: 420px;
}
