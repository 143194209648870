@mixin for-phone-only-small {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-phone-only-intermediary {
  @media (max-width: 700px) {
      @content;
  }
}

@mixin for-phone-only-advanced {
  @media (max-width: 740px) {
      @content;
  }
}

@mixin for-small-tablet-portrait-up {
    @media (min-width: 540px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
